import {ElNotification, ElMessage} from 'element-plus'

const message = {
    message: function (data) {
        switch (data.status) {
            case 422:
                ElNotification({title: data.error.code, message: data.error.fields, type: 'error'})
                break;
            case 500:
                ElMessage({message: data.error.message, type: 'warning'})
                break;
        }
    }
}

export default message
